<template>
  <div class="title-div">
      <div class="left">
          {{title}}
          <Button v-if="showBtn" class="btn" type="primary" size="small" @click="click">{{btnTitle}}</Button>
      </div>
      <div class="right">
          <slot name="titleRight"></slot>
      </div>
  </div>
</template>

<script>
export default {
    props:['title','showBtn','btnTitle'],
    methods:{
        click(){
            this.$emit('click')
        }
    }
}
</script>

<style lang="less" scoped>
.title-div{
    padding: 0 0 0 20px;
    display: flex;
    justify-content: space-between;
    position: relative;
    // padding-left: 20px;
    margin:10px 0;
    &::after{
        position: absolute;
        content:'';
        top: -2.5px;
        left: 0;
        width: 5px;
        height: 25px;
        background-color: #2db7f5;
    }
    .left{
        font-size: 18px;
        font-weight: bold;
        .btn{
            margin-left: 20px;
        }
    }
}
</style>